<template>
  <div class="nestGroupDetail">
    <!--    <div class="left">-->
    <!--      <el-tree ref="treeBox" :highlight-current="true" node-key="id" :expand-on-click-node="false" :data="treedata" :props="defaultProps" default-expand-all @node-click="handleNodeClick"></el-tree>-->
    <!--    </div>-->
    <!--    <div class="middel"></div>-->
    <div class="right" v-if="data">
      <Listcontainer :search="false" @add="add" @fetch="loadData" :addText="`注册设备`" :isAdd="false">
        <template v-slot:grid>
          <Grid
            staticIcon
            :isStatus="true"
            :isMove="true"
            @bind="bind"
            @unbind="unbind"
            :Gridtype="6"
            @add="add"
            @intoGrid="view"
            :isBtnHidden="false"
            :isAdd="nodeType"
            :isDel="true"
            :GridData="data"
          />
        </template>
        <!--        <template v-slot:morebtn>-->
        <!--          <el-popover placement="bottom-end" width="320" v-model="popoverVisible">-->
        <!--            <el-select v-model="deviceTypeId" placeholder="设备类型" style="margin-top: 10px; width: 100%">-->
        <!--              <el-option v-for="item in DeviceTypeOptions" :key="item.deviceTypeId" :label="item.name" :value="item.deviceTypeId"> </el-option>-->
        <!--            </el-select>-->
        <!--            <el-select v-model="deviceStatus" placeholder="设备状态" style="margin-top: 20px; width: 100%">-->
        <!--              <el-option v-for="item in deviceStatusOptions" :key="item.value" :label="item.name" :value="item.value"> </el-option>-->
        <!--            </el-select>-->
        <!--            <div style="text-align: right; margin: 16px 0px 6px 0px">-->
        <!--              <el-button class="iconfont icon-dcsousuo" @click="loadData">查询</el-button>-->
        <!--              <el-button class="iconfont icon-dcshuaxin1" @click="Resetsearch">重置</el-button>-->
        <!--            </div>-->
        <!--            <el-button slot="reference" class="iconfont icon-dcicon_more1">更多筛选</el-button>-->
        <!--          </el-popover>-->
        <!--        </template>-->
        <template v-slot:table>
          <Basetable :multi="false" :data="data" :columns="columns" :operations="operations" />
        </template>
      </Listcontainer>
    </div>
  </div>
</template>

<script>
import Basetable from '@/components/table/base-table/base-table';
import Listcontainer from '@/components/list-container/list-container';
import Grid from '@/components/grid/index.vue';
export default {
  components: {
    Basetable,
    Listcontainer,
    Grid
  },
  data() {
    return {
      searchText: '',
      multipleSelection: [],
      popoverVisible: false,
      deviceTypeId: '',
      DeviceTypeOptions: [],
      deviceStatus: '',
      deviceStatusOptions: [
        {
          value: 2,
          name: '离线'
        },
        {
          value: 1,
          name: '在线'
        },
        {
          value: 0,
          name: '未激活'
        }
      ],
      columns: [
        {
          prop: 'name',
          label: '设备名称',
          href: true,
          handle: this.view
        },
        {
          prop: 'code',
          label: '设备编号'
        },
        {
          prop: 'isOeeStatus',
          label: '平均效率计算'
        },
        {
          prop: 'newDeviceStatus',
          label: '状态'
        },
        {
          prop: 'createdTime',
          label: '注册时间'
        }
      ],
      operations: {
        width: 100,
        handles: [
          {
            icon: 'icon-dcicon-yibangding',
            name: '绑定',
            handle: this.bind
          },
          {
            icon: 'icon-dcicon-weibangding',
            name: '解绑',
            handle: this.unbind
          }
        ]
      },
      pagination: {
        total: 0,
        pageSize: 20,
        currentPage: 1
      },
      routerQuery: null,
      path: '',
      treedata: [],
      data: [],
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      nodeType: false,
      deviceId: '',
      dialogVisible: false,
      checkedId: '',
      lineId: ''
    };
  },
  mounted() {
    this.getTree();
    if (localStorage.getItem('path')) {
      this.path = localStorage.getItem('path');
      if (localStorage.getItem('isDeviceAdd') === '1') {
        this.nodeType = true;
      } else {
        this.nodeType = false;
      }
    }
    localStorage.setItem('schoolTenantId', this.$route.query.tenantId);
    localStorage.setItem('schoolUserId', this.$route.query.userId);
  },
  watch: {
    searchText() {
      this.loadData();
    }
  },
  methods: {
    async getTree() {
      // this.treedata = await this.$apis.FactoryChart.list();
      // // const deviceTypeRes = await this.$apis.device.deviceType();
      // this.DeviceTypeOptions = [];
      // localStorage.setItem('path', this.treedata[0].id);
      // this.path = this.treedata[0].id;
      this.loadData();
    },
    // async handleNodeClick(item) {
    //   this.path = item.id;
    //   localStorage.setItem('path', item.id);
    //   // const data = {
    //   //   currentPage: 1,
    //   //   currentPageSize: 20,
    //   //   keyword: this.searchText,
    //   //   path: item.id
    //   // };
    //   const res = await this.$apis.device.schoolDeviceList({ tenantIdList: [3893324892047872] });
    //   this.data = res.items;
    //   this.data.forEach(item => {
    //     item.hide = item.deviceTypeId !== 0;
    //     item.newDeviceStatus = item.deviceStatus === 2 ? '离线' : item.deviceStatus === 1 ? '在线' : '未激活';
    //     item.isOeeStatus = item.isOee === 1 ? '是' : '否';
    //   });
    //   this.pagination = {
    //     total: res.total,
    //     pageSize: res.currentPageSize,
    //     currentPage: res.currentPage
    //   };
    // },
    async loadData(param = {}) {
      const { currentPage, pageSize } = this.pagination;
      const data = {
        currentPage: param.currentPage || currentPage,
        currentPageSize: param.currentPageSize || pageSize,
        keyword: this.searchText,
        path: this.path,
        deviceTypeId: this.deviceTypeId,
        deviceStatus: this.deviceStatus
      };
      console.log(data);
      const res = await this.$apis.device.schoolDeviceList({ tenantIdList: [this.$route.query.tenantId] });
      this.data = res.data;
      this.data.forEach(item => {
        item.hide = item.deviceTypeId !== 0;
        item.newDeviceStatus = item.deviceStatus === 2 ? '离线' : item.deviceStatus === 1 ? '在线' : '未激活';
        item.isOeeStatus = item.isOee === 1 ? '是' : '否';
      });
      this.pagination = {
        total: res.total,
        pageSize: res.currentPageSize,
        currentPage: res.currentPage
      };
      this.$nextTick(() => {
        // const id = this.path - 0;
        // this.$refs.treeBox.setCurrentKey(id);
      });
    },
    view(item) {
      if (item.deviceTypeId) {
        this.$router.push({ path: '/deviceInfo/detail', query: { data: item.id || item.deviceId } });
      }
    },
    Resetsearch() {
      this.deviceTypeId = '';
      this.deviceStatus = '';
      this.loadData();
    },
    add() {
      if (this.path === '') {
        this.$message.warning('未切换到相应产线，不可添加设备');
      } else {
        this.$router.push({ path: '/deviceInfo/add', query: { data: this.path } });
      }
    },
    bind({ deviceId, iconUrl }) {
      this.$router.push({
        name: 'deviceInfo_add',
        params: {
          id: deviceId,
          iconUrl: process.env.VUE_APP_AESSETS_URL + iconUrl || `${process.env.VUE_APP_AESSETS_URL}ecm/holdhope/2021-11-02/device.png`
        }
      });
    },
    unbind({ deviceId }) {
      this.$confirm('确认解绑此设备吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const { fail } = await this.$apis.device.unbindDevice({ deviceId });
        this.loadData();
        if (!fail) {
          return false;
        }
      });
    }
  }
};
</script>
<style scoped lang="scss">
.nestGroupDetail {
  display: flex;
  width: 100%;
  height: 99%;
  .left {
    padding-right: 20px;
    width: 370px;
    height: 100%;
    overflow-y: auto;
  }
  .middel {
    position: relative;
    bottom: 20px;
    width: 20px;
    height: calc(100% + 40px);
    background-color: #f3f3f3;
  }
  .right {
    padding-left: 20px;
    flex: 1;
    height: 100%;
  }
}
/deep/ .el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
  background-color: #f5f7fa;
  color: #f39800;
}
/deep/ .el-tree-node .is-expanded .is-focusable {
  height: 36px;
}
/deep/ .el-tree-node__content {
  height: 36px;
}
/deep/ .dialog-footer .el-button {
  width: 90px;
  height: 36px;
}
/deep/ .el-dialog__header {
  border-bottom: 1px solid #ebebeb;
}

.dialog {
  /deep/ .el-tree > .el-tree-node > .el-tree-node__content {
    padding-left: 30px;
    height: 42px;
    color: black;
    background-color: #f5f5f5;
  }
  /deep/ .el-tree {
    color: #666666;
  }
  /deep/.el-checkbox__inner {
    border-radius: 50%;
  }
  /deep/.el-checkbox__input.is-disabled {
    display: none;
  }
  /deep/ .el-checkbox {
    transform: translateX(-50px);
  }
  /deep/ .el-dialog__body {
    height: 500px;
    overflow-y: auto;
  }
  /deep/ .el-dialog__footer {
    border-top: 1px solid #ebebeb;
  }
}
</style>
